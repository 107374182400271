<script lang="ts" context="module">import Hotjar from "./hotjar.svelte";
import Impact from "./impact.svelte";
import Meta from "./meta.svelte";
import Mixpanel from "./mixpanel.svelte";
</script>

<script lang="ts">export let edge;
export let session;
export let debug = false;
export let hotjarId;
export let mixpanelToken;
</script>

<Impact {session} />
<Hotjar id={hotjarId} />
<Mixpanel {debug} token={mixpanelToken} {session} />
{#if edge.integrations?.meta}
  <Meta token={edge.integrations.meta} />
{/if}
